/* eslint-disable */
import * as React from 'react'


import Layout from '../components/Layout'
import SEO from '../components/SEO'


interface ILegal {
  
}

const Legal: React.FC<ILegal> = () => {
  

  return (
    <Layout>
      Legal
    </Layout>
  )
}

export default Legal


